import {
  IconLink,
  OwnUpBody,
  OwnUpGridWrapper,
  OwnUpSmallHeadlineBook,
  SAGE_100
} from '@rategravity/own-up-component-library';
import { EmailIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/email';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import React from 'react';
import { HaveQuestionsAddress, HaveQuestionsContent, HaveQuestionsSection } from './elements';

interface HaveQuestionsProps {
  backgroundColor?: React.CSSProperties['backgroundColor'];
  email?: string;
  address?: boolean;
}

export const HaveQuestions = ({
  backgroundColor = SAGE_100,
  email = 'hello@ownup.com',
  address
}: HaveQuestionsProps) => {
  const title = 'Questions?';
  const phone = '(844) 947-2848';

  return (
    <HaveQuestionsSection aria-label="have questions?" style={{ backgroundColor }}>
      <OwnUpGridWrapper>
        <OwnUpSmallHeadlineBook variant="h2">{title}</OwnUpSmallHeadlineBook>
        <HaveQuestionsContent>
          <IconLink link="tel:+1-844-947-2848" icon={PhoneIcon} iconPlacement="left" size="large">
            {phone}
          </IconLink>
          <IconLink link={`mailto:${email}`} icon={EmailIcon} iconPlacement="left" size="large">
            {email}
          </IconLink>
          {address && (
            <HaveQuestionsAddress>
              <OwnUpBody variant="body1">Own Up</OwnUpBody>
              <OwnUpBody variant="body1">50 Milk Street, Floor 16</OwnUpBody>
              <OwnUpBody variant="body1">Boston, MA 02109</OwnUpBody>
            </HaveQuestionsAddress>
          )}
        </HaveQuestionsContent>
      </OwnUpGridWrapper>
    </HaveQuestionsSection>
  );
};
